import React from 'react'

type ParagraphProps = {
  children: React.ReactChild | React.ReactChild[]
  className?: string
}

const Paragraph = ({ children, className }: ParagraphProps) => {
  return <p className={className}>{children}</p>
}

export default React.memo(Paragraph)
