import React from 'react'

type UnorderedListProps = {
  children: React.ReactChild | React.ReactChild[]
}

const UnorderedList = ({ children }: UnorderedListProps) => {
  return <ul className="unordered-list">{children}</ul>
}

export default React.memo(UnorderedList)
