import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import UnorderedList from '../components/UnorderedList'

import '../scss/styles.scss'

const DataProtection = () => {
  return (
    <DefaultLayout
      title={siteData.dataProtection.title}
      activePage={Pages.DATA_PROTECTION}
    >
      <Section>
        <Container>
          <h1 className="title">{siteData.dataProtection.headline}</h1>
          <Paragraph className="is-last">
            Der Schutz Ihrer personenbezogenen Daten bei der Erhebung,
            Verarbeitung und Nutzung anlässlich Ihres Besuchs auf unserer
            Homepage ist uns ein wichtiges Anliegen. Ihre Daten werden im Rahmen
            der gesetzlichen Vorschriften geschützt. Nachfolgend finden Sie
            Informationen, welche Daten während Ihres Besuchs auf der Homepage
            erfasst und wie diese genutzt werden:
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>1. Erhebung und Verarbeitung von Daten</h2>
          <Paragraph>
            Jeder Zugriff auf unsere Homepage und jeder Abruf einer auf der
            Homepage hinterlegten Datei werden protokolliert. Die Speicherung
            dient internen systembezogenen und statistischen Zwecken. Von allen
            Besuchern werden gespeichert und auf unbestimmte Zeit aufbewahrt:
          </Paragraph>
          <UnorderedList>
            <li>Name der abgerufenen Datei</li>
            <li>Datum und Uhrzeit des Abrufs</li>
            <li>übertragene Datenmenge</li>
            <li>Meldung über erfolgreichen/nicht erfolgreichen Abruf</li>
            <li>Statuscode des Webservers</li>
            <li>Webbrowser und Betriebssystem</li>
            <li>anfragende Domain</li>
            <li>der Referrer</li>
            <li>Suchanfragen</li>
          </UnorderedList>
          <Paragraph className="is-last">
            Es werden keine vollständigen IP-Adressen der anfragenden Rechner
            protokolliert. Die letzten Stellen der IP-Adresse werden nach dem
            Zugriff anonymisiert gespeichert. Weitergehende personenbezogene
            Daten werden nur erfasst, wenn Sie diese Angaben freiwillig, etwa im
            Rahmen einer Anfrage oder Registrierung, machen.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>2. Nutzung und Weitergabe personenbezogener Daten</h2>
          <Paragraph className="is-last">
            Soweit Sie uns personenbezogene Daten zur Verfügung gestellt haben,
            verwenden wir diese nur zur Beantwortung Ihrer Anfragen, zur
            Abwicklung mit Ihnen geschlossener Verträge und für die technische
            Administration. Ihre personenbezogenen Daten werden an Dritte nur
            weitergegeben oder sonst übermittelt, wenn dies zum Zwecke der
            Vertragsabwicklung – insbesondere Weitergabe von Bestelldaten an
            Lieferanten – erforderlich ist, dies zu Abrechnungszwecken
            erforderlich ist oder Sie zuvor eingewilligt haben. Sie haben das
            Recht, eine erteilte Einwilligung mit Wirkung für die Zukunft
            jederzeit zu widerrufen. Die Löschung der gespeicherten
            personenbezogenen Daten erfolgt, wenn Sie Ihre Einwilligung zur
            Speicherung widerrufen, wenn ihre Kenntnis zur Erfüllung des mit der
            Speicherung verfolgten Zwecks nicht mehr erforderlich ist oder wenn
            ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>3. Auskunftsrecht</h2>
          <Paragraph>
            Auf schriftliche Anfrage werden wir Sie gern über die zu Ihrer
            Person gespeicherten Daten informieren.
          </Paragraph>
          <Paragraph>
            Sie erhalten jederzeit ohne Angaben von Gründen kostenfrei Auskunft
            über Ihre bei uns gespeicherten Daten. Sie können jederzeit Ihre bei
            uns erhobenen Daten sperren, berichtigen oder löschen lassen. Sie
            können jederzeit die uns erteilten Einwilligung zur Datenerhebung
            und Verwendung ohne Angaben von Gründen widerrufen.
            <br />
            Wenden Sie dazu an{' '}
            <a href="mailto: info@neue-obstsorten.de">
              info@neue-obstsorten.de
            </a>
          </Paragraph>
          <Paragraph className="is-last">
            <b>Sicherheitshinweis:</b>
            <br />
            Wir sind bemüht, Ihre personenbezogenen Daten durch Ergreifung aller
            technischen und organisatorischen Möglichkeiten so zu speichern,
            dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation per
            E-Mail kann die vollständige Datensicherheit von uns nicht
            gewährleistet werden, so dass wir Ihnen bei vertraulichen
            Informationen den Postweg empfehlen.
          </Paragraph>
        </Container>
      </Section>
    </DefaultLayout>
  )
}

export default React.memo(DataProtection)
